'use strict'
class scAllocation
  constructor: ->
    return [
      'ui.tree'
      'allocation.measure'
      'allocation.booking'
      'allocation.gig'
      'allocation.gigger'
      'allocation.zeroTax'
      'allocation.gigImport'
    ]

class allocationConfig
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'main.allocation',
        url: '/allocation'
        abstract: true,
        template: "<div ui-view=''></div>",
        controller: 'allocationController',
        data: {}

class allocation
  constructor: ($state, $scope) ->
    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      $scope.pageTitle = toState.data.pageTitle if angular.isDefined(toState.data.pageTitle)

angular.module('sc.allocation', new scAllocation())
.config(['$stateProvider', allocationConfig])
.controller('allocationController', ['$state', '$scope', allocation])