class scControl
  constructor: ->
    return [
      'angular.filter'
    ]

class allocationConfig
  constructor: ($stateProvider) ->
    $stateProvider

    .state 'main.control',
      url: '/control'
      template: "<div ui-view=''></div>"
      controller: 'controlController',
      data: {}

    # alert is a gig control
    .state 'main.control.alert',
      url: '/alert'
      templateUrl: 'control/alerts.tpl.html'
      controller: 'controlAlertController'
      data: {
        pageTitle: 'Control :: alert'
        pageName: 'Alert'
      }

    # list means extended search
    .state 'main.control.lists',
      url: '/lists'
      templateUrl: 'control/lists.tpl.html'
      controller: 'controlListsController'
      data: {
        pageTitle: 'Control :: lists'
        pageName: 'lists'
      }

    .state 'main.control.key_values',
      url: '/key_values'
      templateUrl: 'control/key_values.tpl.html'
      controller: 'controlKey_valuesController'
      data: {
        pageTitle: 'Control :: key value pairs'
        pageName: 'key value pairs'
      }
      resolve:{
        'picksData': (Picks) ->
          return Picks.picksPromise
      }

    .state 'main.control.measures',
      url: '/measures'
      templateUrl: 'control/measure_deals.tpl.html'
      controller: 'controlMeasureDealController'
      data: {
        pageTitle: 'Control :: measure overview'
        pageName: 'Show all deals for measures'
      }

    .state 'main.control.turnover',
      url: '/turnover'
      templateUrl: 'control/turnover.tpl.html'
      controller: 'controlTurnoverController'
      data: {
        pageTitle: 'Control :: Turnover'
        pageName: 'Turnover'
      }

    .state 'main.control.workload',
      url: '/workload'
      templateUrl: 'control/workload.tpl.html'
      controller: 'controlWorkloadController'
      data: {
        pageTitle: 'Control :: Workload'
        pageName: 'Workload'
      }

    .state 'main.control.booking',
      url: '/booking_control'
      templateUrl: 'control/booking_control.tpl.html'
      controller: 'controlBookingController'
      data: {
        pageTitle: 'Control: Booking'
        pageName: 'Booking'
      }

    .state 'main.control.gigs',
      url: '/gigs'
      templateUrl: 'control/gig_control.tpl.html'
      controller: 'controlGigsController'
      data: {
        pageTitle: 'Control :: Gigs'
        pageName: 'Gigs'
      }

    .state 'main.control.middleman',
      url: '/middleman_control'
      templateUrl: 'control/middleman_control.tpl.html'
      controller: 'controlMiddlemanController'
      data: {
        pageTitle: 'Control: Middleman'
        pageName: 'Middleman'
      }


class control
  constructor: ($state, $scope) ->
    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      $scope.pageTitle = toState.data.pageTitle if angular.isDefined(toState.data.pageTitle)
      $scope.current_user = toState.data.current_user if angular.isDefined(toState.data.pageTitle)
      return

class controlAlert
  constructor: ($state, $scope, Gig, $filter, toastr, $translate) ->
    # Init scope with DateTime today - start at 00:00 and end 23:59
    $scope.data = {
      gigs: []
      gigDateRange:
        startDate: new Date().setUTCHours(0, 0, 0, 0)
        endDate: new Date().setUTCHours(24, 0, 0, 0)
    }

    # Find all bookings for a single gig
    getBookings = (gig) ->
      gigBookingPromise = Gig.service.one(gig._id).one('bookings').get()
      gigBookingPromise.then (response) ->
        gig['bookings'] = response.plain()

    $scope.getGigs = ->
      gigData =
        begin: new Date($scope.data.gigDateRange.startDate)
        end: new Date($scope.data.gigDateRange.endDate)

      $translate("Search", {type: 'Gigs'}).then (text) ->
        toastr.info text

      gigPromise = Gig.service.one().get(gigData)
      gigPromise.then (response) ->
        $scope.data.gigs = response.gigs
        # Loop all gigs and match bookings
        _.forEach(response.gigs, (gig) -> getBookings(gig))
        if response.gigs?.length
          $translate('SearchSuccess', {type: 'Gigs', length: response.gigs.length}).then (text) ->
            toastr.success text
        else
          $translate('SearchWarning').then (text) ->
            toastr.warning text
      , (response) ->
        $translate('ErrorMsg').then (text) ->
          toastr.error text

class controlLists
  constructor: ($scope, $state, Control, Skill, toastr, uiGridConstants, $filter) ->
    # define searchable fields
    $scope.availableSearchParams = [
      {key: "last_name", name: "Last Name", placeholder: "Last Name"},
      {key: "first_name", name: "First Name", placeholder: "First Name"},
      {key: "mail_address", name: "E-Mail", placeholder: "E-Mail"},
      {key: "city_name", name: "City Name", placeholder: "City Name"},
      {key: "city_code", name: "City Code", placeholder: "City Code"}
    ]

    $scope.data =
      customers: []

    $scope.lang = "de"

    $scope.gridOptions =
      enableSorting: true
      paginationPageSize: 10
      enablePaginationControls: false
      enableGridMenu: true
      enableFiltering: true
      exporterMenuPdf: false
      columnDefs: [
        {
          enableFiltering: false,
          name: 'Sex',
          field: 'sex',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Title',
          field: 'title',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'First name',
          field: 'first_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Last name',
          field: 'last_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Name',
          field: 'full_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          name: 'Mail',
          field: 'mails[0].mail_address',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          name: 'Phone',
          field: 'phones[0].number',
          cellFilter: 'tel',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          name: 'city_name',
          field: 'addresses[0].city_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {

          name: 'Adstop',
          field: 'ad_stop',
          enableCellEdit: false,
          headerCellFilter: 'translate',
          cellFilter: 'translate',
          filter: {
            type: uiGridConstants.filter.SELECT,
            selectOptions: [
              {value: 'true', label: 'Ja'},
              {value: 'false', label: 'Nein'}
            ]
          }
        },
        {
          name: 'city_code',
          field: 'addresses[0].city_code',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          name: 'Achievement',
          field: 'achievements[0].name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          name: 'Date',
          field: 'achievements[0].status_date',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          name: 'Status',
          field: 'achievements[0].status',
          enableCellEdit: false,
          headerCellFilter: 'translate',
          cellFilter: 'translate',
          filter: {
            type: uiGridConstants.filter.SELECT,
            selectOptions: [
              {value: 'requested', label: 'angestrebt'},
              {value: 'refused', label: 'verweigert'},
              {value: 'started', label: 'begonnen'},
              {value: 'achieved', label: 'erreicht'},
              {value: 'cat-state', label: 'unbekannt'}
            ]
          }
        },
        {
          name: 'tags',
          field: 'tags.join(", ")'
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          'name': '#',
          'field': '_id',
          'sortable': false,
          'cellTemplate': '<a data-ui-sref="main.customer.edit.dashboard({customerId: COL_FIELD })">
                            <i class="fa fa-edit fa-2x"></i></a>',
          'enableCellEdit': false,
          'width': '50'

        }

      ]
    $scope.gridOptions.onRegisterApi = (gridApi) ->
      $scope.gridApi2 = gridApi

    skills = []
    tags = []

    # get all skills on page load
    getSkills = ->
      Skill.service.one().get().then (response) ->
        _(response.skills).forEach((n) ->
          skills.push({'_id': n._id, name: n.name, type: 'skill'}))
        init()

    getSkills()

    getTags = ->
      Control.customerTagService.one().get().then (response) ->
        _(response.tags).forEach((n, index) ->
          tags.push({'_id': index, 'name': n, 'type': 'tag'}))
        init()

    getTags()

    # combine all tags and skills into one array
    init = ->
      $scope.searchItems = _.union(skills, tags)

    $scope.startSearch = ->
      $scope.searchPromise = Control.customerFilterService.post($scope.searchParams)
      $scope.searchPromise.then (response) ->
        for c in response.customers
          c['full_name'] = $filter('fullName')(c)
          c['_achievements'] = _.filter(c.achievements, {'skill_id': $scope.searchParams.skills[0]})
          if not $scope.searchParams.skills[0]
            c['_achievements'] = _.takeRight(c.achievements)
          for i in c['_achievements']
            i['status_date'] = $filter('naiveToLocal')(i['status_date']) if i['status_date']
        $scope.gridOptions.data = response.customers
      , (response) ->
        toastr.error response.data.message

class controlKey_values
  constructor: ($scope, $state, Control, Customer, Skill, toastr, Picks, uiGridConstants, $filter, $translate) ->

    $scope.data =
      customers: []
    $scope.key_value = ''
    $scope.key_values_picks = Picks.getPicksByName('key_values')?.pick_values

    $scope.lang = "de"

    $scope.gridOptions =
      enableSorting: true
      paginationPageSize: 10
      enablePaginationControls: false
      enableGridMenu: true
      enableFiltering: true
      exporterMenuPdf: false
      columnDefs: [
        {
          enableFiltering: false,
          name: 'First name',
          field: 'first_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Last name',
          field: 'last_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Name',
          field: 'full_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          name: 'Key',
          field: 'key_value.key',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: true
        },
        {
          enableFiltering: false,
          name: 'Key value',
          field: 'key_value.value',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: true
        },
        {
          enableFiltering: false,
          'name': '#',
          'field': '_id',
          'sortable': false,
          'cellTemplate': '<a data-ui-sref="main.customer.edit.dashboard({customerId: COL_FIELD })">
                            <i class="fa fa-edit fa-2x"></i></a>',
          'enableCellEdit': false,
          'width': '50'

        }

      ]
    $scope.gridOptions.onRegisterApi = (gridApi) ->
      $scope.gridApi2 = gridApi

    $scope.startSearch = ->
      $scope.searchPromise = Customer.customerKeyValueService.get($scope.key_value)
      $scope.searchPromise.then (response) ->
        for c in response.customers
          c['full_name'] = $filter('fullName')(c)
        $scope.gridOptions.data = response.customers
        toastr.success $translate.instant('Search completed.')
      , (response) ->
        toastr.error response.data.message

class controlMeasureDeal
  constructor: ($scope, $state, Control, Measure, toastr, $translate) ->
    $scope.showMeasure = true
    $scope.daterange =
      startDate: null
      endDate: null

    $scope.getMeasures = ->
      data =
        search_start: $scope.daterange.startDate
        search_end: $scope.daterange.endDate
      Control.measureDealControlService.post(data).then (response) ->
        $scope.measures = response.measures

    $scope.getMeasureDeals = (measure) ->
      dealsPromise = Measure.service.one(measure._id).one('deals').get()
      dealsPromise.then (data) ->
        measure['deals'] = data.deals

class controlTurnover
  constructor: ($state, $scope, Control, toastr, $translate, $filter) ->
    $scope.lineOptions =
      scales:
        xAxes: [{
          stacked: true
        }],
        yAxes: [
          {
            id: "turnover-y"
            stacked: true
            display: true
            ticks:
              min: 0
              beginAtZero: true
            position: "left"
          }
        ]

    $scope.lineData = {
      labels: []
      datasets: []
    }

    $scope.dateRangePicker = {
      dateRange:
        startDate: moment().subtract(6,'months').startOf('month')
        endDate: moment().add(6, 'months').endOf('month')
      options:
        locale:
          format: 'DD.MM.YYYY'
    }

    lineColors =
      raised:
        id: 'turnover-y'
        type: 'line'
        bgC: "rgba(224,224,224,0.5)"
        bC: "rgba(224,224,224,0.8)"
        pBC: "rgba(224,224,224,1)"
      vague:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(255,204,153,0.5)"
        bC: "rgba(255,204,153,0.8)"
        pBC: "rgba(255,204,153,1)"
      confident:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(255,178,102,0.5)"
        bC: "rgba(255,178,102,0.8)"
        pBC: "rgba(255,178,102,1)"
      justified:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(255,153,51,0.5)"
        bC: "rgba(255,153,51,0.8)"
        pBC: "rgba(255,153,51,1)"
      fulfilled:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(0,102,204,0.5)"
        bC: "rgba(0,102,204,0.8)"
        pBC: "rgba(0,102,204,1)"
      lost:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(51,0,0,0.5)"
        bC: "rgba(51,0,0,0.8)"
        pBC: "rgba(51,0,0,1)"
      provisional:
        id: 'turnover-y'
        fill: true
        type: 'bar'
        bgC: "rgba(102,255,102,0.5)"
        bC: "rgba(102,255,102,0.8)"
        pBC: "rgba(102,255,102,1)"
      debited:
        id: 'turnover-y'
        fill: true
        type: 'bar'
        bgC: "rgba(0,204,0,0.5)"
        bC: "rgba(0,204,0,0.8)"
        pBC: "rgba(0,204,0,1)"


    $scope.query = ->
      runQuery()

    runQuery = ->
      $translate('QueryStart').then (text) ->
        toastr.info text
      #prepare start and end date
      data = {
        start_date: $scope.dateRangePicker.dateRange.startDate
        end_date: $scope.dateRangePicker.dateRange.endDate
      }
      queryPromise = Control.turnoverService.post(data)
      queryPromise.then (response) ->

        # reset datasets
        $scope.lineData.datasets.length = 0

        $translate('QuerySuccess').then (text) ->
          $scope.lineData.labels = response.labels

          for dataset in response.datasets
            dataset['yAxisID'] = lineColors[dataset.label].id
            dataset['type'] = lineColors[dataset.label].type
            dataset['fill'] = lineColors[dataset.label].fill
            dataset['backgroundColor'] = lineColors[dataset.label].bgC
            dataset['borderColor'] = lineColors[dataset.label].bC
            dataset['pointBorderColor'] = lineColors[dataset.label].pBC
            dataset['pointBackgroundColor'] = "#fff"
            dataset['pointBorderWidth'] = 1
            dataset['pointHoverRadius'] = 5
            dataset['pointHoverBackgroundColor'] = "rgba(255,205,86,1)"
            dataset['pointHoverBorderColor'] = "rgba(255,205,86,1)"
            dataset['pointHoverBorderWidth'] = 2
          $scope.lineData.datasets = response.datasets
          toastr.success text
      , (response) ->
        console.log(response)
        $translate('QueryError').then (text) ->
          toastr.warning text
    # initially run the query
    runQuery()

class controlWorkload
  constructor: ($state, $scope, Control, toastr, $translate, $filter) ->
    colors =
      submitted:
        backgroundColor: "rgba(203,98,49,0.5)"
        borderColor: "rgba(203,98,49,0.8)"
        borderWidth: 1
        hoverBackgroundColor: "rgba(203,98,49,0.75)"
        hoverBorderColor: "rgba(203,98,49,1.0)"
      offered:
        backgroundColor: "rgba(203,203,49,0.5)"
        borderColor: "rgba(203,203,49,0.8)"
        borderWidth: 1
        hoverBackgroundColor: "rgba(203,203,49,0.75)"
        hoverBorderColor: "rgba(203,203,49,1.0)"
      signed:
        backgroundColor: "rgba(98,203,49,0.5)"
        borderColor: "rgba(98,203,49,0.8)"
        borderWidth: 1
        hoverBackgroundColor: "rgba(98,203,49,0.75)"
        hoverBorderColor: "rgba(98,203,49,1.0)"


    $scope.barData =
      labels: []
      datasets: []

    $scope.barDataOptions =
      responsive: true
      stacked: true
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          stacked: true
        }]
      }

    $scope.dateRangePicker = {
      dateRange:
        startDate: moment().subtract(1,'months').startOf('month')
        endDate: moment().add(3, 'months').endOf('month')
      options:
        locale:
          format: 'DD.MM.YYYY'
    }

    $scope.query = ->
      runQuery()

    runQuery = ->
      $translate('QueryStart').then (text) ->
        toastr.info text
      #prepare start and end date
      data = {
        start_date: $scope.dateRangePicker.dateRange.startDate
        end_date: $scope.dateRangePicker.dateRange.endDate
        supervisor_id: $scope.supervisor._id if $scope.supervisor
      }
      queryPromise = Control.workloadService.post(data)
      queryPromise.then (response) ->

        # reset datasets
        $scope.barData.datasets.length = 0

        $translate('QuerySuccess').then (text) ->
          toastr.success text

        $scope.barData.labels = response.labels
        for dataset in response.datasets
          dataset['backgroundColor'] = colors[dataset.label].backgroundColor
          dataset['borderColor'] = colors[dataset.label].borderColor
          dataset['borderWidth'] = 1
          dataset['hoverBackgroundColor'] = colors[dataset.label].hoverBackgroundColor
          dataset['hoverBorderColor'] = colors[dataset.label].hoverBorderColor
        $scope.barData.datasets = response.datasets

      , (response) ->
        console.log(response)
        $translate('QueryError').then (text) ->
          toastr.warning text

    # initially run the query
    runQuery()

class controlBooking
  constructor: ($state, $scope, Control, toastr, $translate, $filter) ->

    $scope.datepickerOptions =
      locale:
        format: 'DD.MM.YYYY'
    $scope.daterange =
      startDate: moment().subtract(1,'months').startOf('month')
      endDate: moment().subtract(1,'months').endOf('month')

    $scope.search = ->
      data =
        start_date: $scope.daterange.startDate
        end_date: $scope.daterange.endDate
      toastr.info("Please be patient. This may take a while.", "Searching...")
      $scope.searchPromise = Control.bookingControlService.post(data)
      $scope.searchPromise.then (response) ->
        toastr.success("Bookings found", "Search successful.")
        $scope.data = response
      , (response) ->
        toastr.error(response.error)
        $scope.errors = response.data.errors

    $scope.getBookingAge = (bookingDate) ->
      if bookingDate then moment(new Date(bookingDate)).fromNow() else moment().fromNow()

class controlGigs
  constructor: ($scope, $rootScope, $state, Control, toastr, $translate, $filter) ->

    $scope.gigs = []

    $scope.getGigAge = (gigDate) ->
      if gigDate then moment(new Date(gigDate)).fromNow() else moment().fromNow()

    search = ->
      $translate('QueryStart').then (text) ->
        toastr.info text

      queryPromise = Control.gigsControlService.one().get()
      queryPromise.then (response) ->
        $translate('QuerySuccess').then (text) ->
          $scope.results = response.plain()
          toastr.success text
      , (response) ->
        console.log(response)
        $translate('QueryError').then (text) ->
          toastr.warning text
    search()

class controlMiddleman
  constructor: ($state, $scope, Control, toastr, $translate, $filter) ->

    $scope.datepickerOptions =
      locale:
        format: 'DD.MM.YYYY'
    $scope.daterange =
      startDate: moment().subtract(1,'years').startOf('year')
      endDate: moment().subtract(1,'years').endOf('year')

    $scope.data = {
      middleman_id: null
      middleman_type: null
      contactFilter: [
        {name: "Company", fields: ["name", "number"]}
        {name: "Customer", fields: ["first_name", "last_name", "number"]}
      ]
    }

    $scope.totals =
      total_deals: 0
      total_debited: 0.0
      total_provisional: 0.0
      total_assignee_count: 0
      total_attendee_count: 0

    $scope.selectMiddleman = (model, filter) ->
      $scope.data.middleman_id = model._id
      $scope.data.middleman_type = filter

    $scope.search = ->
      #prepare start and end date
      payload =
        start_date: $scope.daterange.startDate
        end_date: $scope.daterange.endDate
        middleman_id: $scope.data.middleman_id
        middleman_type: $scope.data.middleman_type

      toastr.info("Please be patient. This may take a while.", "Searching...")
      $scope.searchPromise = Control.middlemanControlService.post(payload)
      $scope.searchPromise.then (response) ->
        toastr.success("Deals found", "Search successful.")
        $scope.deals = response.deals
        $scope.totals = response.totals
      , (response) ->
        toastr.error("This search can't be executed.", "Bad search")
        $scope.errors = response.data.errors


angular.module('sc.control', new scControl())
.controller('controlController', ['$state', '$scope', control])
.controller('controlAlertController', ['$state', '$scope', 'Gig', '$filter', 'toastr', '$translate', controlAlert])
.controller('controlListsController', ['$scope', '$state', 'Control', 'Skill', 'toastr', 'uiGridConstants', '$filter', controlLists])
.controller('controlKey_valuesController', ['$scope', '$state', 'Control', 'Customer', 'Skill', 'toastr', 'Picks', 'uiGridConstants', '$filter', '$translate', controlKey_values])
.controller('controlMeasureDealController', ['$scope', '$state', 'Control', 'Measure', 'toastr', '$translate', controlMeasureDeal])
.controller('controlTurnoverController', ['$state', '$scope', 'Control', 'toastr', '$translate', '$filter', controlTurnover])
.controller('controlWorkloadController', ['$state', '$scope', 'Control', 'toastr', '$translate', '$filter', controlWorkload])
.controller('controlBookingController', ['$state', '$scope', 'Control', 'toastr', '$translate', '$filter', controlBooking])
.controller('controlGigsController', ['$scope', '$rootScope', '$state', 'Control', 'toastr', '$translate', '$filter', controlGigs])
.controller('controlMiddlemanController', ['$state', '$scope', 'Control', 'toastr', '$translate', '$filter', controlMiddleman])

angular.module('sc.allocation')
.config(['$stateProvider', allocationConfig])