class allocationMeasure
  constructor: ->
    return []

class measureConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.allocation.measure',
      url: '/measure'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.allocation.measure.create',
      url: "/create",
      templateUrl: "allocation/measure/measure.tpl.html",
      controller: "allocationMeasureCreateController",
      data:
        pageTitle: 'Create a measure',
        pageName: 'View measure'

    .state 'main.allocation.measure.edit',
      url: "/edit/:measureId",
      templateUrl: "allocation/measure/measure.tpl.html",
      controller: "allocationMeasureEditController",
      resolve:
        measure_deals: (Measure, $stateParams) ->
          Measure.service.one($stateParams.measureId).one('deals').get().then (data) ->
            return data.deals
      data:
        pageTitle: 'Edit a measure',
        pageName: 'View measure'

    .state 'main.allocation.measure.edit.dashboard',
      url: "/dashboard",
      templateUrl: "allocation/measure/description.tpl.html",
      controller: "allocationMeasureEditDashboardController",
      resolve:
        gigs: (Measure, $stateParams) ->
          Measure.service.one($stateParams.measureId).one('gigs').get().then (data) ->
            return data
      data:
        pageTitle: 'Edit a measure',
        pageName: 'View measure'

    .state 'main.allocation.measure.edit.fees',
      url: "/fees",
      templateUrl: "allocation/measure/fees.tpl.html",
      controller: "allocationMeasureEditFeesController",
      data:
        pageTitle: 'Edit a measure',
        pageName: 'View measure'

    .state 'main.allocation.measure.edit.assignees',
      url: "/assignees",
      templateUrl: "allocation/measure/assignees.tpl.html",
      data:
        pageTitle: 'Edit a measure',
        pageName: 'View measure'

    .state 'main.allocation.measure.edit.structure',
      url: "/structure",
      templateUrl: "allocation/measure/structure.tpl.html",
      controller: "allocationMeasureEditStructureController",
      resolve:
        gigs: (Measure, $stateParams) ->
          Measure.service.one($stateParams.measureId).one('gigs').get().then (data) ->
            return data
      data:
        pageTitle: 'Edit a measure',
        pageName: 'View measure'

    .state 'main.allocation.measure.edit.supervisors',
      url: "/supervisors",
      templateUrl: "allocation/measure/supervisors.tpl.html",
      controller: "allocationMeasureEditSupervisorsController",
      data:
        pageTitle: 'Edit a measure',
        pageName: 'View measure'

    .state 'main.allocation.measure.edit.comments',
      url: "/comments",
      templateUrl: "allocation/measure/comments.tpl.html",
      data:
        pageTitle: 'Edit a measure',
        pageName: 'View measure'

class allocationMeasureCreate
  constructor: ($scope, $state, $stateParams, $translate, Measure, Draft, Region, Gig, toastr) ->
    $scope.measure =
      status: 'projected'
      measure_type: 'public'
      fixed_start: new Date()
      fixed_end: new Date()

    $scope.saveMeasure = ->
      Measure.service.post($scope.measure)
      .then (data) ->
        $translate('CreateMsg', {name: data.name}).then (text) ->
          toastr.success text
        $state.go 'main.allocation.measure.edit.dashboard', {'measureId': data._id}

      , (response) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

class allocationMeasureEdit
  constructor: ($scope, $state, $stateParams, $translate,
    Measure, Draft, Region, Gig, toastr, Deal, $filter, $resource, measure_deals) ->
    $scope.measure_deals = measure_deals

    $scope.statusColor =
      'offered': 'text-warning'
      'signed': 'text-success'
      'virgin': 'text-warning'
      'submited': 'text-warning'

    $scope.countAssignees = ->
      assignees_count = 0
      for deal in _.filter(measure_deals, {'status': 'signed'})
        assignees_count += deal['assignees'].length
      assignees_count

    $scope.model = []
    # setup to filter customer and companies in typeaheadFilterSearch
    $scope.data = {
      contactFilter: [
        {name: "Customer", fields: ["first_name", "last_name", "number"]}
        {name: "Company", fields: ["name", "number"]}
      ]
      statusTypes: Measure.statusTypes
    }

    $scope.newFee = {}

    $scope.measure =
      supervisors: []
      contacts: []
      fees: []

    $scope.measure_typeOptions = [
      {name: "public", value: "public"},
      {name: "in-house", value: "in-house"}
    ]

    $scope.datePickerOptions =
      locale:
        format: 'DD.MM.YYYY'
      eventHandlers:
        'apply.daterangepicker': (ev, picker) ->
          # convert the daterange to start and end dates
          $scope.measure.fixed_end = ev.model.endDate
          $scope.measure.fixed_start = ev.model.startDate
          $scope.saveMeasure()

    $scope.addSkill = (item, editor) ->
      if $scope.measure.hasOwnProperty('skills')
        $scope.measure.skills.push({'skill_id': item._id})
      else
        $scope.measure['skills'] = [{'skill_id': item._id}]
      $scope.saveMeasure()

    # Get all mail addresses for gigs in module
    getMails = (data) ->
      mails = []

      for attendee in data
        if attendee['mails'].length
          mails.push(attendee['mails'][0]['mail_address'])

      return mails.join('; ')

    prepareCsv = (data) ->
      lines = []

      for attendee in data

        ilias = _.filter(attendee['credentials'], {credential_type: 'ILIAS'})

        _line =
          a: attendee['title']
          b: attendee['first_name']
          c: attendee['last_name']
          d: $filter('salutationLetter')(attendee)
          e: attendee['mails'][0]['mail_address'] if attendee['mails'].length
          f: attendee['phones'][0]['number'] if attendee['phones'].length
          g: attendee['addresses'][0]['address_type'] if attendee['addresses'].length
          h: attendee['addresses'][0]['street'] if attendee['addresses'].length
          i: attendee['addresses'][0]['city_code'] if attendee['addresses'].length
          j: attendee['addresses'][0]['city_name'] if attendee['addresses'].length
          k: attendee['default_address']['address_type'] if attendee['default_address']
          l: attendee['default_address']['recipient_type'] if attendee['default_address']
          m: $filter('fullName')(attendee['default_address']) if attendee['default_address']
          n: attendee['default_address']['street'] if attendee['default_address']
          o: attendee['default_address']['city_code'] if attendee['default_address']
          p: attendee['default_address']['city_name'] if attendee['default_address']
          q: ilias[0]['user_name'] if ilias.length
          r: ilias[0]['password'] if ilias.length
          s: attendee['measure_name']

        lines.push(_line)

      return lines


    # Get all relevant data for attendees
    $scope.getAttendeeData = (module) ->
      module.busy = true

      data =
        gig_ids: []

      _.forEach(module.agendas, (agenda) ->
        data.gig_ids.push(agenda.gig.gig_id)
      )

      module.attendeePromise = Measure.service.one($scope.measure._id).one('data').customPOST(data)
      module.attendeePromise.then (response) ->
        module.mails = getMails(response)
        module.csv_data = prepareCsv(response)
        module.ready = true
        module.copy = true

    $scope.getCsv = (module) ->
      _file = module.csv_data
      _file

    $scope.getHeader = ->
      return [
        "title",
        "first_name",
        "last_name",
        "salutation",
        "mail_address",
        "phone",
        "address_type",
        "street",
        "city_code",
        "city_name",
        "default_address_type",
        "default_contact_type",
        "default_fullName",
        "default_street",
        "default_city_code",
        "default_city_name",
        "ilias_user_name",
        "ilias_password",
        "measure_name"
      ]

    # load measure with given id
    if $stateParams.measureId?.length
      measurePromise = Measure.service.one($stateParams.measureId).get()
      measurePromise.then (data) ->
        $scope.measure = data
        $scope.daterange =
          startDate: moment(new Date(data.fixed_start))
          endDate: moment(new Date (data.fixed_end))
      , (response) ->
        toastr.error(response.error)


    $scope.selectRegion = (region) ->
      $scope.measure.region.region_id = region._id
      $scope.saveMeasure()

    $scope.useDraft = (draft) ->
      if $scope.measure.courses.length
        toastr.error "Structure detected."
      else
        $scope.measure.draft = draft._id
        # Copy draft structure to the current measure
        $scope.measure.courses = draft.courses.slice(0)
        $scope.measure.fees = draft.fees.slice(0)
        $scope.measure.supervisors = draft.supervisors.slice(0)
        $scope.measure.skills = draft.skills.slice(0)
        $scope.saveMeasure()

    $scope.clearRegion = ->
      $scope.measure.region.region_id = null
      $scope.saveMeasure()

    $scope.clearMeasure = ->
      $state.go 'main.allocation.measure.create'

    $scope.cloneMeasure = ->
      $scope.measure._id = ''
      $scope.measure.name = $scope.measure.name + " - Clone"
      toastr.success("Measure " + $scope.measure.name + " was born.", "Clone successful.")
      $scope.saveMeasure()

    measurePartialUpdateKeys = ['region', 'skills']
    measurePartialUpdate = (newData) ->
      _.forEach(measurePartialUpdateKeys, (key) ->
        $scope.measure[key] = newData[key]
      )


    # save measure and decide between post and put
    $scope.saveMeasure = ->
      $scope.measure.tags = _.map($scope.measure.tags, 'text')
      if $scope.measure._id
        Measure.service.one($scope.measure._id)
        .customPUT($scope.measure)
        .then (data) ->
          $scope.errors = []
          # Cant set $scope.measure = data -> strange behaviour when editing measure structure
          #@TODO: Use more components or directives
          measurePartialUpdate(data)
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
      else
        Measure.service.post($scope.measure)
        .then (data) ->
          $scope.measure = data
          $scope.daterange = {startDate: data.fixed_start, endDate: data.fixed_end}
          $scope.errors = []
          toastr.success(data.name + " saved.", "Save successful.")
        , (response) ->
          $scope.errors = response.data.errors

    $scope.deleteMeasure = ->
      Measure.service.one($scope.measure._id)
      .customDELETE()
      .then (data) ->
        $scope.errors = []
        toastr.success(data.name + " was eliminated.", "Good bye my fellow measure.")
      , (response) ->
        $scope.errors = response.data.errors

    measure_overview = $resource('measure/:measure_id/overview', {measure_id: $stateParams.measureId},
      pdf:
        method: 'GET'
        header:
          accept: 'application/pdf'
        responseType: 'arraybuffer'
        cache: false
        transformResponse: (data, header) ->
          decoder = new TextDecoder()
          msg = decoder.decode(data)
          pdf = undefined
          if data
            pdf = new Blob([data], {type: 'application/pdf'})
          return {
            response: pdf
            text: msg
          }
    )

    $scope.measureOverview = (name) ->
      measure_overview.pdf(measure_id: $stateParams.measureId).$promise
      .then (data) ->
        blob = data.response
        saveAs(blob, name + '.pdf')
      , (error) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error error.data.text

    #measure actions
    $scope.execute = (action) ->
      payload = {'action': action}
      Measure.service.one($scope.measure._id).one('action').customPOST(payload).then (response) ->
        toastr.success 'Action successfully executed'
      , (response) ->
        console.log(response)
        toastr.error(response.data.message)

class allocationMeasureEditDashboard
  constructor: ($scope, gigs) ->

    # Bind gigs result from resolve to scope
    $scope.gigs = gigs

#    # Show Measure structure as tree start with initial tree
#    $scope.view =
#      tree:
#        'name': 'M'
#        'parent': 'null'
#        'childrenType': 'course'
#    # change d3 tree to show measure and to update on changes
#    changeTree = (measure) ->
#      $scope.measure = _.cloneDeep(measure)
#      _measure = _.cloneDeep(measure)
#      root =
#        _id: _measure._id
#        name: ''
#        parent: 'null'
#        children: []
#
#      courses = {}
#      # build tree recursive
#      makeChildren = (obj, item) ->
#        child = []
#        for i of obj
#          child.push obj[i]
#          item.children = child
#          if obj[i].modules
#            makeChildren obj[i].modules, obj[i]
#          if obj[i].sequences
#            makeChildren obj[i].sequences, obj[i]
#
#      makeChildren _measure.courses, courses
#
#      if courses.children
#        root.children = courses.children
#      # bind tree to scope
#      $scope.view.tree = root
#
#    changeTree($scope.measure)

class allocationMeasureEditStructure
  constructor: ($scope, $state, $stateParams, $translate, Measure, Gig, toastr, gigs) ->

    # Add a new item to given collection
    $scope.addSubItem = (model, name, subCollection) ->
      dct = {}
      dct[subCollection] = []
      dct['name'] = name
      model.push(dct)
      $scope.saveMeasure()
    # Delete an item from given collection
    $scope.deleteItem = (model, item) ->
      model = model.splice(model.indexOf(item), 1)[0]
      $scope.saveMeasure()

    # Bind gigs result from resolve to scope
    $scope.gigs = gigs

    # Save on sortable event
    $scope.sortableOptions =
        update: ->
          $scope.saveMeasure()

    $scope.orderAgendas = (agendas) ->
      for agenda in agendas
        if agenda.gig?
          agenda['begin'] = $scope.gigs[agenda.gig.gig_id].begin if $scope.gigs[agenda.gig.gig_id]?
      return agendas

    $scope.createGig = (model, agenda) ->
      model.tags = _.map(model.tags, 'text')
      Gig.service.post(model).then (response) ->
        agenda['gig'] =
          gig_id: response._id
        $scope.gigs[response._id] = response
        $scope.saveMeasure()

    $scope.selectGig = (model, agenda) ->
      agenda['gig'] =
        gig_id: model._id
      $scope.gigs[model._id] = model
      $scope.saveMeasure()

    $scope.wipeGig = (item) ->
      item.gig = {}
      $scope.saveMeasure()

    $scope.saveMeasure = ->
      $scope.measure.tags = _.map($scope.measure.tags, 'text')
      # convert the daterange to start and end dates
      $scope.measure.fixed_end = $scope.daterange.endDate
      $scope.measure.fixed_start = $scope.daterange.startDate
      if $scope.measure._id
        Measure.service.one($scope.measure._id)
        .customPUT($scope.measure)
        .then (data) ->
          $scope.errors = []
          # Renew scope to get gig relations
          $scope.measure = data
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors

class allocationMeasureEditSupervisors
  constructor: ($scope) ->

    # prepare user data and save measure
    $scope.addSupervisor = (user) ->
      user.user_id = user._id
      user.topic = $scope.data.supervisorTopic
      $scope.measure.supervisors.push(user)
      $scope.saveMeasure()
      $scope.data.supervisorTopic = null

    $scope.selectContact = (model, filter) ->
      model.contact_id = model._id
      model.topic = $scope.data.contactTopic
      model.contact_type = filter

    # prepare contact data and save measure
    $scope.addContact = (contact) ->
      $scope.measure.contacts.push(contact)
      $scope.saveMeasure()
      $scope.data.contactTopic = null


class allocationMeasureEditFees
  constructor: ($scope) ->

    $scope.newFee = {}

    $scope.selectRevenueAccount = (model) ->
      $scope.newFee.revenue_account = model

    $scope.selectCostUnit = (model) ->
      $scope.newFee.cost_unit = model

    #Push single fee into $scope.data.fees
    $scope.addFee = ->
      $scope.measure.fees.push
        name: $scope.newFee.name
        amount: $scope.newFee.amount
        currency: $scope.newFee.currency
        tax_free: $scope.newFee.tax_free
        deferrable: $scope.newFee.deferrable
        discountable: $scope.newFee.discountable
        revenue_account: $scope.newFee.revenue_account
        cost_unit: $scope.newFee.cost_unit
      $scope.saveMeasure()
      $scope.newFee = {}


angular.module('allocation.measure', new allocationMeasure())
.config(['$stateProvider', '$provide', measureConfig])
.controller('allocationMeasureCreateController', ['$scope', '$state', '$stateParams', '$translate', 'Measure', 'Draft', 'Region', 'Gig', 'toastr', allocationMeasureCreate])
.controller('allocationMeasureEditController', ['$scope', '$state', '$stateParams', '$translate', 'Measure', 'Draft', 'Region', 'Gig', 'toastr', 'Deal', '$filter', '$resource', 'measure_deals', allocationMeasureEdit])
.controller('allocationMeasureEditDashboardController', ['$scope', 'gigs', allocationMeasureEditDashboard])
.controller('allocationMeasureEditStructureController', ['$scope', '$state', '$stateParams', '$translate', 'Measure', 'Gig', 'toastr', 'gigs', allocationMeasureEditStructure])
.controller('allocationMeasureEditSupervisorsController', ['$scope', allocationMeasureEditSupervisors])
.controller('allocationMeasureEditFeesController', ['$scope', allocationMeasureEditFees])