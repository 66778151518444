class gigImport
  constructor: ->
    return []

class gigImportConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
      .state 'main.allocation.gigImport',
      url: "/gig_import",
      templateUrl: "allocation/gig_import/gig_import.tpl.html",
      controller: "allocationGigImportController",
      data:
        pageTitle: 'Gig :: import',
        pageName: 'Gig import'

class allocationGigImport
  constructor: ($scope, $translate, Gig, toastr) ->
    $scope.csv =
      content: null
      result: null

    gigs = []

    prepareGig = (gig) ->
      data =
        name: gig.name
        begin: moment("#{gig.begin_date} #{gig.begin_time}", "DD.MM.YYYY HH:mm")
        end: moment("#{gig.end_date} #{gig.end_time}", "DD.MM.YYYY HH:mm")
        tags: gig.tags.split(",") if gig.tags

      gigs.push(data)

    $scope.send = ->
      $translate('ImportGigStart').then (text) ->
        toastr.info text

      gigs = []
      for gig in $scope.csv.result
        prepareGig gig

      data =
        'gigs': gigs

      Gig.importService.post(data).then (response) ->

        # Reset imported csv
        $scope.errors = false
        $scope.csv.content = null
        $scope.csv.result.length = 0
        gigs.length = 0
        $translate('ImportGigSuccess', {'length': response.length}).then (text) ->
          toastr.success text
      , (error) ->
        $scope.errors = true

        for _error  in error.data.errors
          _errorString = _error.name.split(".")
          $scope.csv.result[_errorString[1]]['error'] = _error.description

        $translate("ErrorMsg").then (text) ->
          toastr.error text


angular.module('allocation.gigImport', new gigImport())
.config(['$stateProvider', '$provide', gigImportConfig])
.controller('allocationGigImportController', ['$scope', '$translate', 'Gig', 'toastr', allocationGigImport])