class adminCostUnit
  constructor: ->
    return []

class costUnitConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.allocation.gig',
      url: '/gig'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.allocation.gig.create',
      url: "/create",
      templateUrl: "allocation/gig/gig.tpl.html",
      controller: "allocationGigCreateController",
      data:
        pageTitle: 'Gig :: create',
        pageName: 'View gig'

    .state 'main.allocation.gig.edit',
      url: "/edit/:gigId",
      templateUrl: "allocation/gig/gig.tpl.html",
      controller: "allocationGigEditController",
      data:
        pageTitle: 'Gig :: edit',
        pageName: 'View gig'

class allocationGigCreate
  constructor: ($scope, $state, Gig, toastr) ->
    $scope.gig = {
      begin: new Date()
      end: new Date()
      location: {}
    }

    $scope.saveGig = ->
      Gig.service.post($scope.gig).then (response) ->
        $state.go 'main.allocation.gig.edit', {'gigId': response._id}


class allocationGigEdit
  constructor: ($rootScope, $scope, $state, $stateParams, Gig, toastr, $translate, $resource) ->

    $scope.lesson =
      content: ""
      duration: (2700000000)
      points: []
      documents: []
      requirements: []
# Default time 09:00
      begin: new Date().setTime(1432278036286)

    $scope.attendeeStatus = Gig.attendeeStatus

    $scope.editItem = (data) ->
      console.log('')

    countAttendees = () ->
      capacity = $scope.gig.location.capacity
      used = $scope.gig.attendees.length
      free_type = if capacity - used > 0 then 'success' else 'danger'
      free = Math.abs(capacity - used)
      $scope.capacity =
        max: used + free
        stacks:[
          {type: 'info', value: used}
          {type: free_type, value: free}
        ]

    getBookings = ->
# get the detail of the bookings for the gig
      gigBookingPromise = Gig.service.one($stateParams.gigId).one('bookings').get()
      gigBookingPromise.then (bookingData) ->
        $scope.gig.bookings = bookingData
      , (response) ->
        console.log 'error', response
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

    if $stateParams.gigId?.length
      gigPromise = Gig.service.one($stateParams.gigId).get()
      gigPromise.then (response) ->
        $scope.gig = response
        getBookings()
        countAttendees()
        $scope.attendee_mails = getMails()

    $scope.selectLocation = (model) ->
      model.location_id = model._id
      $scope.gig.location = model
      $scope.editorEnabled = !$scope.editorEnabled
      $scope.saveGig()

    $scope.addAttendee = (model) ->
      _model =
        status: $scope.attendee.status
        attendee_id: model.customerModel._id
        measure_id: model.measureModel._id
        deal_id: model.dealModel._id

      $scope.gig.attendees.push(_model)
      $scope.attendee = {}
      $scope.saveGig()

    $scope.addTrainer = (model) ->
      model.trainer_id = model._id
      $scope.gig.trainers.push(model)
      $scope.trainer = {}
      $scope.saveGig()

    $scope.selectPointType = (model) ->
      $scope.newPoint.point_type =
        point_type_id: model._id
        name: model.name

    $scope.addPoint = () ->
      newPoint = $scope.newPoint
      $scope.lesson.points.push(newPoint)
      $scope.newPoint = null
      $scope.newPointType = null

    $scope.addLesson = () ->
      lesson = $scope.lesson
      $scope.gig.lessons.push(lesson)
      $scope.saveGig()

    $scope.useAgenda = (agenda) ->
# Copy draft structure to the current measure
      $scope.gig.lessons = agenda.lessons
      $scope.saveGig()

    $scope.clearLocation = ->
      $scope.gig.location = {}
      $scope.saveGig()

    $scope.approve = ->
      $scope.gig.approval =
        user_id: $rootScope.state.user._id
        full_name: $rootScope.state.user.full_name
        approval_date: new Date()
      $scope.saveGig()

    # Get all mail addresses for gigs in module
    getMails = ->
      mails = []

      for attendee in $scope.gig.attendees
        if attendee['mails'].length
          mails.push(attendee['mails'][0]['mail_address'])

      return mails.join('; ')

    participation_document = $resource('gig/:gig_id/participation', { gig_id: $stateParams.gigId },
      pdf:
        method: 'GET'
        header:
          accept: 'application/pdf'
        responseType: 'arraybuffer'
        cache: false
        transformResponse: (data, header) ->

          decoder = new TextDecoder()
          msg = decoder.decode(data)
          pdf = undefined
          if data
            pdf = new Blob([data], { type: 'application/pdf'})
          return {
            response: pdf
            text: msg
          }
    )

    $scope.document = (name) ->
      participation_document.pdf().$promise
      .then (data) ->
        blob = data.response
        saveAs(blob, name + '.pdf')
      , (error) ->
        toastr.error error.data.text
    $scope.clearGig = ->
      toastr.success("Gig wiped.", "Wipe successful.")
      $state.go 'main.allocation.gig.create'

    $scope.cloneGig = ->
      $scope.gig._id = ''
      $scope.gig.tags = _.map($scope.gig.tags, 'text')
      $scope.gig.name = $scope.gig.name + " - Clone"
      $scope.gig.comments = []
      toastr.success("Gig " + $scope.gig.name + " was born.", "Clone successful.")
      Gig.service.post($scope.gig).then (response) ->
        $state.go 'main.allocation.gig.edit', {'gigId': response._id}

    #We need to transform ISO Dates to unix timestamps
    transformLessons = (lessons) ->
      return _.reduce lessons, (transformed_lessons, lesson) ->
        lesson.begin = moment(lesson.begin).valueOf()
        transformed_lessons.push(lesson)
        return transformed_lessons
      , []

    $scope.saveGig = ->
      $scope.gig.tags = _.map($scope.gig.tags, 'text')
      $scope.gig.lessons = transformLessons($scope.gig.lessons)
      Gig.service.one($stateParams.gigId).customPUT($scope.gig).then (response) ->
        countAttendees()
        $scope.gig = response
        $translate('EditMsg', {name: response.name}).then (text) ->
          toastr.success text


angular.module('allocation.gig', new adminCostUnit())
.config(['$stateProvider', '$provide', costUnitConfig])
.controller('allocationGigCreateController', ['$scope', '$state', 'Gig', 'toastr', allocationGigCreate])
.controller('allocationGigEditController', ['$rootScope', '$scope', '$state', '$stateParams', 'Gig', 'toastr', '$translate', '$resource', allocationGigEdit])