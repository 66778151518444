initialTreeData = [{
  'name': 'New draft'
  'parent': 'null'
  'childrenType': 'course'
}]

class scStage
  constructor: ->
    return [
      'restangular'
      'ui.sortable'
      'ui.bootstrap'
      'ui.router'
      'sc.directive'
      'sc.factory'
    ]

class stageConfig
  constructor: ($stateProvider, $provide) ->

    $stateProvider
    .state 'main.stage',
      abstract: true,
      url: '/stage'
      templateUrl: "stage/stage.tpl.html",
      controller: 'stageController',
      data: {}

    .state 'main.stage.draft',
      url: '/draft'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.draft.create',
      url: "/create"
      templateUrl: "stage/draft.tpl.html",
      controller: "stageDraftCreateController",
      data:
        pageTitle: 'Create a Draft',
        pageName: 'View Draft'

    .state 'main.stage.draft.edit',
      url: "/edit/:draftId",
      templateUrl: "stage/draft.tpl.html",
      controller: "stageDraftEditController",
      data:
        pageTitle: 'Edit a Draft',
        pageName: 'View Draft'

    .state 'main.stage.course',
      url: '/course'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.course.create',
      url: "/create"
      templateUrl: "stage/course.tpl.html",
      controller: "stageCourseCreateController",
      data:
        pageTitle: 'Create a Course',
        pageName: 'View Course'

    .state 'main.stage.course.edit',
      url: "/edit/:courseId",
      templateUrl: "stage/course.tpl.html",
      controller: "stageCourseEditController",
      data:
        pageTitle: 'Edit a Course',
        pageName: 'View Course'

    .state 'main.stage.sequence',
      url: '/sequence'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.sequence.create',
      url: "/create"
      templateUrl: "stage/sequence.tpl.html",
      controller: "stageSequenceCreateController",
      data:
        pageTitle: 'Create a Sequence',
        pageName: 'View Sequence'

    .state 'main.stage.sequence.edit',
      url: "/edit/:sequenceId",
      templateUrl: "stage/sequence.tpl.html",
      controller: "stageSequenceEditController",
      data:
        pageTitle: 'Edit a Sequence',
        pageName: 'View Sequence'

    .state 'main.stage.module',
      url: '/module'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.module.create',
      url: "/create"
      templateUrl: "stage/module.tpl.html",
      controller: "stageModuleCreateController",
      data:
        pageTitle: 'Create a Module',
        pageName: 'View Module'

    .state 'main.stage.module.edit',
      url: "/edit/:moduleId",
      templateUrl: "stage/module.tpl.html",
      controller: "stageModuleEditController",
      data:
        pageTitle: 'Edit a Module',
        pageName: 'View Module'

    .state 'main.stage.agenda',
      url: '/agenda'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.agenda.create',
      url: "/create"
      templateUrl: "stage/agenda.tpl.html",
      controller: "stageAgendaCreateController",
      data:
        pageTitle: 'Create a Agenda',
        pageName: 'View Agenda'

    .state 'main.stage.agenda.edit',
      url: "/edit/:agendaId",
      templateUrl: "stage/agenda.tpl.html",
      controller: "stageAgendaEditController",
      data:
        pageTitle: 'Edit a Agenda',
        pageName: 'View Agenda'

    .state 'main.stage.lesson',
      url: '/lesson'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.lesson.create',
      url: "/create"
      templateUrl: "stage/lesson.tpl.html",
      controller: "stageLessonCreateController",
      data:
        pageTitle: 'Create a Lesson',
        pageName: 'View Lesson'

    .state 'main.stage.lesson.edit',
      url: "/edit/:lessonId",
      templateUrl: "stage/lesson.tpl.html",
      controller: "stageLessonEditController",
      data:
        pageTitle: 'Edit a Lesson',
        pageName: 'View Lesson'

    .state 'main.stage.region',
      url: '/region'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.picks',
      url: '/picks'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.picks.create',
      url: "/create"
      templateUrl: "stage/picks.tpl.html",
      controller: "stagePicksCreateController",
      data:
        pageTitle: 'Create a Picks',
        pageName: 'View Picks'

    .state 'main.stage.picks.edit',
      url: "/edit/:pickId",
      templateUrl: "stage/picks.tpl.html",
      controller: "stagePicksEditController",
      data:
        pageTitle: 'Edit a Picks',
        pageName: 'View Picks'

class stage
  constructor: ($state, $scope) ->
    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      $scope.pageTitle = toState.data.pageTitle if angular.isDefined(toState.data.pageTitle)
      return


class stageDraftCreate
  constructor: ($state, $scope, toastr, Draft, $uibModal) ->
#build initial tree
    $scope.view =
      tree:
        'name': ''
        'parent': 'null'
        'childrenType': 'course'

    $scope.draft = {
      'name': ''
    }

    # Create a draft
    $scope.saveDraft = ->
      $scope.draft.name = $scope.view.tree.name
      Draft.service.post($scope.draft)
      .then (data) ->
        toastr.success(data.name + " saved.", "Save successful.")
        $state.go 'main.stage.draft.edit', {'draftId': data._id}
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)

class stageDraftEdit
  constructor: ($state, $stateParams, $scope, toastr, Draft, $uibModal) ->
    $scope.draft = {}
    $scope.data =
      statusTypes: Draft.statusTypes

    $scope.view =
      tree:
        'name': ''
        'parent': 'null'
        'childrenType': 'course'

    $scope.newFee =
      revenue_account: {}
      cost_unit: {}

    $scope.saveImage = ->
      html = d3.select('svg').node().parentNode.innerHTML
      $scope.imgsrc = 'data:image/png;base64,'+ btoa(html)

    if $stateParams.draftId?.length
      draftPromise = Draft.service.one($stateParams.draftId).get()
      draftPromise.then (response) ->
        $scope.draft = response
        $scope.changeTree(response)

    $scope.clearDraft = ->
      $state.go 'main.stage.draft.create'

    $scope.cloneDraft = ->
      $scope.draft._id = ''
      $scope.draft.name = $scope.draft.name + ' - Clone'
      $scope.saveDraft()

    $scope.deleteDraft = ->
      Draft.service.one($scope.draft._id).customDELETE().then ((data) ->
        toastr.success data.name + ' was eliminated.', 'Good bye my fellow draft.'
        $scope.clearDraft()
        return
      ), (response) ->
        $scope.errors = response.data.errors
        toastr.error response.error
        return
      return

    # function to prepare draft data for tree building
    $scope.changeTree = (draft) ->
      $scope.draft = _.cloneDeep(draft)
      _draft = _.cloneDeep(draft)
      root =
        _id: _draft._id
        name: _draft.name
        parent: 'null'
        children: []
      courses = {}

      makeChildren = (obj, item) ->
        child = []
        for i of obj
          child.push obj[i]
          item.children = child
          if obj[i].modules
            makeChildren obj[i].modules, obj[i]
          if obj[i].sequences
            makeChildren obj[i].sequences, obj[i]
          if obj[i].agendas
            makeChildren obj[i].agendas, obj[i]

      makeChildren _draft.courses, courses
      if courses.children
        root.children = courses.children
      $scope.view.tree = root
    # Opens a modal dialog to edit the draft - uses a custom template and its own controller
    # Resolves to pass current draft and the function to change the tree
    $scope.open = ->
      $uibModal.open({
        templateUrl: 'stage/draft_edit.tpl.html'
        controller: 'stageDraftModalController'
        resolve: {
          model: ->
            return $scope.draft

          updateTree: ->
            return $scope.changeTree
        }
      })

    $scope.addItem = (item, modelName) ->
      _item = {}
      switch modelName
        when "courses" then _item.course_id = item._id
        when "supervisors" then _item.user_id = item._id
        when "skills" then _item.skill_id = item._id
      $scope.draft[modelName].push(_item)
      $scope.search = {}
      $scope.saveDraft()

    $scope.selectRevenueAccount = (model) ->
      $scope.newFee.revenue_account.revenue_account_id = model._id

    $scope.selectCostUnit = (model) ->
      $scope.newFee.cost_unit.cost_unit_id = model._id

    # Push single fee into $scope.data.fees
    $scope.addFee = ->
      $scope.draft.fees.push
        name: $scope.newFee.name
        amount: $scope.newFee.amount
        currency: $scope.newFee.currency
        tax_free: $scope.newFee.tax_free
        deferrable: $scope.newFee.deferrable
        discountable: $scope.newFee.discountable
        revenue_account: $scope.newFee.revenue_account
        cost_unit: $scope.newFee.cost_unit
      $scope.saveDraft()

    $scope.saveDraft = ->
      if $scope.draft._id
        $scope.draft.name = $scope.view.tree.name
        Draft.service.one($scope.draft._id)
        .customPUT($scope.draft)
        .then (data) ->
          $scope.draft = data
          $scope.changeTree(data)
          toastr.success(data.name + " edited.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)
      else
        Draft.service.post($scope.draft)
        .then (data) ->
          $scope.draft = data
          $scope.changeTree(data)
          toastr.success(data.name + " saved.")
          $state.go 'main.stage.draft.edit', {'draftId': data._id}
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)


class stageDraftModal
  constructor: ($scope, $rootScope, model, Draft, toastr, updateTree) ->
    $scope.data = model

    $scope.save = ->
      Draft.service.one($scope.data._id)
      .customPUT($scope.data)
      .then (data) ->
        $scope.data = data
        updateTree(data)
        toastr.success(data.name + " saved.")
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)

    $scope.addItem = (item, modelName) ->
      switch modelName
        when "courses" then item.course_id = item._id
        when "supervisors" then item.user_id = item._id
      $scope.data[modelName].push(item)
      $scope.save()


class stageCourseCreate
  constructor: ($scope, $state, Course, toastr) ->
    $scope.course = {
      name: ""
      sequences: []
    }

    $scope.saveCourse = ->
      coursePromise = Course.post($scope.course)
      coursePromise.then (response) ->
        $state.go 'main.stage.course.edit', {'courseId': response._id}


class stageCourseEdit
  constructor: ($scope, $state, $stateParams, Course, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.course = {
      name: ""
      sequences: []
    }

    if $stateParams.courseId?.length
      coursePromise = Course.one($stateParams.courseId).get()
      coursePromise.then (response) ->
        $scope.course = response

    $scope.selectCourse = (course) ->
      $scope.course = course
      toastr.success(course.name + " was selected.", "Selection successful.")
      return

    $scope.clearCourse = ->
      $scope.course =
        name: ""
        sequences: []
      toastr.success("Course wiped.", "Wipe successful.")

    $scope.cloneCourse = ->
      $scope.course._id = ''
      $scope.course.name = $scope.course.name + " - Clone"
      toastr.success("Course " + $scope.course.name + " was born.", "Clone successful.")
      $scope.saveCourse()

    $scope.addSequence = (sequence) ->
      sequence.sequence_id = sequence._id
      $scope.course.sequences.push(sequence)
      $scope.sequence = {}
      $scope.saveCourse()
      return

    $scope.saveCourse = ->
      if $scope.course._id
        Course.one($scope.course._id)
        .customPUT($scope.course)
        .then (data) ->
          $scope.course = data
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)
      else
        Course.post($scope.course)
        .then (data) ->
          $scope.course._id = data._id
          toastr.success(data.name + " saved.", "Save successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)

    $scope.deleteCourse = ->
      Course.one($scope.course._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Good bye my fellow course.")
        $scope.clearCourse()
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)

class stageModuleCreate
  constructor: ($scope, $state, Module, toastr) ->
    $scope.module =
      name: ''
      agendas: []

    $scope.saveModule = ->
      modulePromise = Module.post($scope.module)
      modulePromise.then (response) ->
        $state.go 'main.stage.module.edit', {'moduleId': response._id}


class stageModuleEdit
  constructor: ($scope, $stateParams, Module, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.module =
      name: ''
      agendas: []

    if $stateParams.moduleId?.length
      modulePromise = Module.one($stateParams.moduleId).get()
      modulePromise.then (response) ->
        $scope.module = response

    # Handle - part of the fix for ui-sortable
    # Stop - event to handle onDragDropStop, method to set sequence.position
    $scope.sortableOptions = {
      handle: '.handle'
      stop: (e, ui) ->
        $scope.saveModule()
    }

    # TypeaheadSearchResult to scope
    $scope.selectModule = (module) ->
      $scope.module = module
      return

    # Clear $scope.module
    $scope.clearModule = ->
      $scope.module =
        name: ""
        agendas: []
      toastr.success("Module wiped.", "Wipe successful.")

    # Clone the current module and sets module._id to null
    $scope.cloneModule = ->
      $scope.module._id = ''
      $scope.module.name = $scope.module.name + " - Clone"
      toastr.success("Module " + $scope.module.name + " was born.", "Clone successful.")
      $scope.saveModule()

    $scope.addAgenda = (agenda) ->
      agenda.agenda_id = agenda._id
      $scope.module.agendas.push(agenda)
      $scope.agenda = {}
      $scope.saveModule()

    # Save a module - decide between post and put
    $scope.saveModule = ->
      if $scope.module._id
        Module.one($scope.module._id)
        .customPUT($scope.module)
        .then (data) ->
          $scope.module = data
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.status)
      else
        Module.post($scope.module)
        .then (data) ->
          $scope.module._id = data._id
          toastr.success(data.name + " saved.", "Save successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.status)

    $scope.deleteModule = ->
      Module.one($scope.module._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Good bye my fellow module.")
        $scope.clearModule()
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class stageSequenceCreate
  constructor: ($scope, $state, Sequence, toastr) ->
    $scope.sequence = {
      name: ''
      modules: []
    }

    $scope.saveSequence = ->
      sequencePromise = Sequence.post($scope.sequence)
      sequencePromise.then (response) ->
        $state.go 'main.stage.sequence.edit', {'sequenceId': response._id}


class stageSequenceEdit
  constructor: ($scope, $stateParams, toastr, Sequence) ->
    $scope.sequence =
      name: ""
      modules: []

    if $stateParams.sequenceId?.length
      sequencePromise = Sequence.one($stateParams.sequenceId).get()
      sequencePromise.then (response) ->
        $scope.sequence = response

    $scope.sortableOptions = {
      stop: (e, ui) ->
        $scope.saveSequence()
    }

    # Clear $scope.agenda
    $scope.clearSequence = ->
      $scope.sequence = {
        name: ""
        modules: []
      }
      toastr.success("Sequence wiped.", "Wipe successful.")

    # Clone the current agenda and sets agenda._id to null
    $scope.cloneSequence = ->
      $scope.sequence._id = ''
      $scope.sequence.name = $scope.sequence.name + " - Clone"
      toastr.success("Sequence " + $scope.sequence.name + " was born.", "Clone successful.")
      $scope.saveSequence()

    $scope.selectSequence = (model) ->
      $scope.sequence = model
      return

    $scope.addModule = (module)->
      module.module_id = module._id
      $scope.sequence.modules.push(module)
      $scope.saveSequence()
      return

    $scope.saveSequence = ->
      if $scope.sequence._id
        Sequence.one($scope.sequence._id)
        .customPUT($scope.sequence)
        .then (data) ->
          $scope.sequence = data
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.status)
      else
        Sequence.post($scope.sequence)
        .then (data) ->
          $scope.sequence._id = data._id
          toastr.success(data.name + " saved.", "Save successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.status)

    $scope.deleteSequence = ->
      Sequence.one($scope.sequence._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Good bye my fellow module.")
        $scope.clearSequence()
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class stageAgendaCreate
  constructor: ($scope, $state, Agenda, toastr) ->
    $scope.agenda =
      name: ""
      lessons: []

    $scope.saveAgenda = ->
      agendaPromise = Agenda.post($scope.agenda)
      agendaPromise.then (response) ->
        $state.go 'main.stage.agenda.edit', {'agendaId': response._id}


class stageAgendaEdit
  constructor: ($scope, $stateParams, toastr, Agenda) ->
    $scope.agenda = {
      name: ""
      lessons: []
    }

    $scope.sortableOptions = {
      stop: (e, ui) ->
        $scope.saveAgenda()
    }

    if $stateParams.agendaId?.length
      agendaPromise = Agenda.one($stateParams.agendaId).get()
      agendaPromise.then (response) ->
        $scope.agenda = response

    # Clear $scope.agenda
    $scope.clearAgenda = ->
      $scope.agenda = {
        name: ""
        lessons: []
      }
      toastr.success("Agenda wiped.", "Wipe successful.")

    # Clone the current agenda and sets agenda._id to null
    $scope.cloneAgenda = ->
      $scope.agenda._id = ''
      $scope.agenda.name = $scope.agenda.name + " - Clone"
      toastr.success("Agenda " + $scope.agenda.name + " was born.", "Clone successful.")
      $scope.saveAgenda()

    $scope.selectAgenda = (model) ->
      _(model.lessons).forEach (item) ->
        item.begin_time = new Date(item.begin)
      $scope.agenda = model
      return

    $scope.addLesson = (lesson) ->
      lesson.lesson_id = lesson._id
      lesson.begin_time = new Date(32428800000)
      lesson.begin = lesson.begin_time.getTime()
      $scope.agenda.lessons.push(lesson)
      $scope.lesson = {}
      $scope.saveAgenda()
      return

    $scope.saveLesson = (lesson) ->
      lesson.begin = lesson.begin.getTime()
      $scope.saveAgenda()

    $scope.saveAgenda = ->
      if $scope.agenda._id
        Agenda.one($scope.agenda._id)
        .customPUT($scope.agenda)
        .then (data) ->
          $scope.agenda = data
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.status)
      else
        Agenda.post($scope.agenda)
        .then (data) ->
          $scope.agenda._id = data._id
          toastr.success(data.name + " saved.", "Save successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.status)

    $scope.deleteAgenda = ->
      Agenda.one($scope.agenda._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Good bye my fellow module.")
        $scope.clearAgenda()
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class stageLessonCreate
  constructor: ($scope, $state, Lesson, toastr) ->
    $scope.lesson =
      content: ""
      duration: new Date()
      points: []
      documents: []
      requirements: []

    $scope.saveLesson = ->
      $scope.lesson.duration = $scope.lesson.duration.getTime()
      lessonPromise = Lesson.post($scope.lesson)
      lessonPromise.then (response) ->
        $state.go 'main.stage.lesson.edit', {'lessonId': response._id}


class stageLessonEdit
  constructor: ($scope, $stateParams, $state, Lesson, toastr) ->
    $scope.lesson =
      points: []
      documents: []
      requirements: []

    if $stateParams.lessonId?.length
      lessonPromise = Lesson.one($stateParams.lessonId).get()
      lessonPromise.then (data) ->
        $scope.lesson = data
      , (response) ->
        toastr.error(response.error)

    $scope.newPoint = {}

    $scope.selectPointType = (model) ->
      $scope.newPoint.point_type =
        point_type_id: model._id
        name: model.name

    $scope.addPoint = () ->
      newPoint = $scope.newPoint
      $scope.lesson.points.push(newPoint)
      $scope.newPoint = null
      $scope.newPointType = null
      $scope.saveLesson()

    $scope.clearLesson = ->
      $state.go 'main.stage.lesson.create'
      toastr.success("Lesson wiped.", "Wipe successful.")

    $scope.cloneLesson = ->
      $scope.lesson._id = ''
      $scope.lesson.content = $scope.lesson.content + " - Clone"
      $scope.saveLesson()

    $scope.selectLesson = (lesson) ->
      $state.go("main.stage.lesson", {lessonId: lesson._id})
      return

    $scope.saveLesson = ->
      $scope.lesson.duration = new Date($scope.lesson.duration).getTime()
      if $scope.lesson._id
        Lesson.one($scope.lesson._id)
        .customPUT($scope.lesson)
        .then (data) ->
          $scope.lesson = data
          toastr.success(data.content + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)
      else
        Lesson.post($scope.lesson)
        .then (data) ->
          toastr.success(data.content + " was born.")
          $state.go 'main.stage.lesson.edit', {'lessonId': data._id}
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)

    $scope.deleteLesson = ->
      Lesson.one($scope.lesson._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.content + " was eliminated.", "Good bye my fellow lesson.")
        $state.go 'main.stage.lesson.create'
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)

    $scope.addRequirement = (skill)->
      skill.skill_id = skill._id
      skill.requirement_type = "trainer"
      $scope.lesson.requirements.push(skill)
      $scope.saveLesson()
      return

    $scope.addDocument = (document)->
      document.document_id = document._id
      $scope.lesson.documents.push(document)
      $scope.saveLesson()
      return


class stagePicksCreate
  constructor: ($scope, $state, Picks, toastr) ->

    $scope.picks = {}
    $scope.pick = {
      name: ''
      pick_values: []
    }
    $scope.pickVals = []

    $scope.getPicks = ->
      Picks.picksService.one().get().then (data) ->
        $scope.picks = data.picks
        # console.log $scope.picks
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)
    $scope.getPicks()

    $scope.savePicks = ->

      Picks.picksService.post($scope.pick)
      .then (data) ->
        toastr.success(data.name + " was born.")
        $state.go 'main.stage.picks.edit', {'pickId': data._id}
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class stagePicksEdit
  constructor: ($scope, $stateParams, $state, Picks, toastr) ->

    if $stateParams.pickId?.length
      Picks.picksService.one($stateParams.pickId).get().then (data) ->
        $scope.pick = data
        $scope.pickVals = data.pick_values
      , (response) ->
        toastr.error(response.error)

    $scope.clearPicks = ->
      $state.go 'main.stage.picks.create'
      toastr.success("Picks wiped.", "Wipe successful.")

    $scope.clonePicks = ->
      $scope.pick._id = ''
      $scope.pick.name = $scope.pick.name + " - Clone"
      $scope.savePicks()

    $scope.selectPicks = (picks) ->
      $state.go("main.stage.picks", {pickId: picks._id})
      return

    $scope.splitter = (obj) ->
      if obj && obj.split
        sp = obj.split(",")
        for e of sp
          sp[e] = sp[e].trim()
        return _.uniqBy(sp)
      else return null

    $scope.savePicks = ->
      $scope.pick.pick_values = $scope.splitter($scope.pickVals)

      if $scope.pick._id
        Picks.picksService.one($scope.pick._id)
        .customPUT($scope.pick)
        .then (data) ->
          $scope.pick = data
          toastr.success(data.name + " saved.", "Edit successful.")
          $state.go 'main.stage.picks.edit', {'pickId': data._id}
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)
      else
        Picks.picksService.post($scope.pick)
        .then (data) ->
          toastr.success(data.name + " was born.")
          $state.go 'main.stage.picks.edit', {'pickId': data._id}
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)

    $scope.deletePicks = ->
      Picks.picksService.one($scope.pick._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Good bye my fellow picks.")
        $state.go 'main.stage.picks.create'
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


angular.module('sc.stage', new scStage())
.config(['$stateProvider', '$provide', stageConfig])
.controller('stageController', ['$state', '$scope', stage])
.controller('stageDraftCreateController', ['$state', '$scope', 'toastr', 'Draft', '$uibModal', stageDraftCreate])
.controller('stageDraftEditController', ['$state', '$stateParams', '$scope', 'toastr', 'Draft', '$uibModal', stageDraftEdit])
.controller('stageDraftModalController', ['$scope', '$rootScope', 'model', 'Draft', 'toastr', 'updateTree', stageDraftModal])
.controller('stageCourseCreateController', ['$scope', '$state', 'Course', 'toastr', stageCourseCreate])
.controller('stageCourseEditController', ['$scope', '$state', '$stateParams', 'Course', 'toastr', stageCourseEdit])
.controller('stageModuleCreateController', ['$scope', '$state', 'Module', 'toastr', stageModuleCreate])
.controller('stageModuleEditController', ['$scope', '$stateParams', 'Module', 'toastr', stageModuleEdit])
.controller('stageSequenceCreateController', ['$scope', '$state', 'Sequence', 'toastr', stageSequenceCreate])
.controller('stageSequenceEditController', ['$scope', '$stateParams', 'toastr', 'Sequence', stageSequenceEdit])
.controller('stageAgendaCreateController', ['$scope', '$state', 'Agenda', 'toastr', stageAgendaCreate])
.controller('stageAgendaEditController', ['$scope', '$stateParams', 'toastr', 'Agenda', stageAgendaEdit])
.controller('stageLessonCreateController', ['$scope', '$state', 'Lesson', 'toastr', stageLessonCreate])
.controller('stageLessonEditController', ['$scope', '$stateParams', '$state', 'Lesson', 'toastr', stageLessonEdit])
.controller('stagePicksCreateController', ['$scope', '$state', 'Picks', 'toastr', stagePicksCreate])
.controller('stagePicksEditController', ['$scope', '$stateParams', '$state', 'Picks', 'toastr', stagePicksEdit])