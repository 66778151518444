class scLocation
  constructor: ->
    return []

class locationConfig
  constructor: ($stateProvider) ->
    $stateProvider
    .state 'main.location',
      abstract: true
      url: '/location'
      templateUrl: 'location/location.tpl.html'
      data: {}

    .state 'main.location.create',
      url: '/create'
      templateUrl: 'location/create_wizard.tpl.html'
      controller: 'locationCreateController'
      data:
        pageTitle: 'Location :: create'

    .state 'main.location.edit',
      abstract: true
      url: '/edit/:locationId'
      templateUrl: 'location/edit.tpl.html'
      controller: 'locationEditController'
      data: {
        pageTitle: ''
      }

    .state 'main.location.edit.dashboard',
      url: '/dashboard'
      templateUrl: 'location/dashboard.tpl.html'
      controller: 'locationEditDashboardController'
      data: {
        pageTitle: 'Location View :: dashboard'
        pageName: 'Dashboard'
      }

    .state 'main.location.edit.basic',
      url: '/basic'
      templateUrl: 'location/basic.tpl.html'
      controller: 'locationEditBasicsController'
      data: {
        pageTitle: 'Location View :: basic'
        pageName: 'Edit basic'
      }

    .state 'main.location.edit.addresses',
      url: '/addresses'
      templateUrl: 'location/address.tpl.html'
      controller: 'locationEditAddressesController'
      data: {
        pageTitle: 'Address View :: addresses'
        pageName: 'Edit addresses'
      }

    .state 'main.location.edit.comments',
      url: '/comments'
      templateUrl: 'views/comment.tpl.html'
      controller: 'locationEditCommentsController'
      data: {
        pageTitle: 'Location View :: comments'
        pageName: 'Comments'
      }

class LocationCreate
  constructor: ($scope, $rootScope, $state, toastr, Location, $translate) ->

#Wizard configuration
    $scope.step = 1
    $scope.wizard =
      show: (number) ->
        $scope.step = number
      next: ->
        $scope.step++
      prev: ->
        $scope.step--

    # Pass options for summernote-editor
    $scope.options = Location.summernoteOptions

    $scope.types =
      address: Location.addressTypes

    $scope.data =
      addresses: []
      comments: []

    $scope.createLocation = ->
      if $scope.data.commentTitle || $scope.data.commentBody
        $scope.data.comments.push({
          author: $rootScope.state.user.user_name
          body: $scope.data.commentBody
          title: $scope.data.commentTitle
        })

      $scope.data.tags = _.map($scope.data.tags, 'text')

      Location.service.post($scope.data).then (response) ->
        $translate('CreateMsg', {name: response.name}).then (text) ->
          toastr.success text

        #Jump to location dashboard
        $state.go 'main.location.edit.dashboard', {'locationId': response._id}

      , (response) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

class locationEdit
  constructor: ($scope, $rootScope, $http, $state, $stateParams, Location, $translate, toastr) ->
    $scope.data = {}

    if $stateParams.locationId
      locationPromise = Location.service.one($stateParams.locationId).get()
      locationPromise.then (data) ->
        $scope.data = data

    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      if (angular.isDefined(toState.data.pageTitle))
        $scope.pageName = toState.data.pageName

    $scope.saveLocation = ->
      $scope.data.tags = _.map($scope.data.tags, 'text')

      Location.service.one($scope.data._id).customPUT($scope.data).then (response) ->
        $scope.data = response
        $translate('EditMsg', {name: response.name}).then (text) ->
          toastr.success text
        $scope.data.errors = []
      , (response) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.data.errors = response.data.errors

class locationEditDashboard
  constructor: ($scope, $sce, $state, $stateParams) ->


class locationEditBasics
  constructor: ($scope, $state, $stateParams, Location) ->
    $scope.types =
      phone: Location.phoneTypes
      mail: Location.mailTypes

class locationEditAddresses
  constructor: ($scope, $state, $stateParams, Location) ->
    $scope.types =
      address: Location.addressTypes

class locationEditComments
  constructor: ($scope, $sce, $rootScope, Location, $state, toastr, $translate) ->
    $scope.options = Location.summernoteOptions

    $scope.addComment = ->
      if $scope.data.commentTitle && $scope.data.commentBody
        $scope.data.comments.push({
          author: $rootScope.state.user.user_name
          body: $scope.data.commentBody
          title: $scope.data.commentTitle
        })
        $scope.data.tags = _.map($scope.data.tags, 'text')
        title = $scope.data.commentTitle
        Location.service.one($scope.data._id).customPUT($scope.data).then (response) ->
          $scope.data = response
          toastr.success title + ' was added.', 'Save successful.'
      else
        toastr.error $translate.instant('Please fill out comment title and comment body')


angular.module('sc.location', new scLocation())
.config(['$stateProvider', locationConfig])
.controller('locationCreateController', ['$scope', '$rootScope', '$state', 'toastr', 'Location', '$translate', LocationCreate])
.controller('locationEditController', ['$scope', '$rootScope', '$http', '$state', '$stateParams', 'Location', '$translate', 'toastr', locationEdit])
.controller('locationEditDashboardController', ['$scope', '$sce', '$state', '$stateParams', locationEditDashboard])
.controller('locationEditBasicsController', ['$scope', '$state', '$stateParams', 'Location', locationEditBasics])
.controller('locationEditAddressesController', ['$scope', '$state', '$stateParams', 'Location', locationEditAddresses])
.controller('locationEditCommentsController', ['$scope', '$sce', '$rootScope', 'Location', '$state', 'toastr', '$translate', locationEditComments])